import React, { Component } from "react";
import styles from "./Footer.module.scss";
import { ReactComponent as Share } from "../../assets/share.svg";
import logoImg from "../../assets/YST-LOGO_MASTER_BLUE_DARK_GREY_32.png";
import ReactHtmlParser from "html-react-parser";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../../Context/ThemeContext";
import { isMobile } from "react-device-detect";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
    };
    window.addEventListener("resize", this.updateDimensions);
  }

  toggleShare = () => {
    this.props.toggleSharepage(false);
  };
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  _renderContent = (theme) => {
    const mobileFooter = theme ? theme.mobileFooter : null;
    const desktopFooter = theme ? theme.desktopFooter : null;

    const isValidMobileFooter =
      mobileFooter &&
      Object.keys(mobileFooter).length > 0 &&
      (mobileFooter.heading ||
        (mobileFooter.quickLink && mobileFooter.quickLink.length > 0));

    const isValidDesktopFooter =
      desktopFooter &&
      Object.keys(desktopFooter).length > 0 &&
      (desktopFooter.heading ||
        (desktopFooter.quickLink && desktopFooter.quickLink.length > 0));

    return (
      <>
        {isMobile &&
          (!isValidMobileFooter ? (
            <div>
              {theme?.footer?.hideDefaultLogo !== undefined &&
              theme?.footer?.hideDefaultLogo ? (
                ""
              ) : (
                <>
                  <img
                    src={logoImg}
                    alt="logo"
                    className={styles["footer-logo"]}
                  />{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://yousmartthing.com/uk"
                    className={styles["footer-a"]}
                  >
                    <strong className={styles["link-text"]}>
                      You. Smart. Thing.
                    </strong>
                  </a>
                </>
              )}
            </div>
          ) : (
            <div>
              <span>
                {mobileFooter?.heading && ReactHtmlParser(mobileFooter.heading)}
              </span>
              {mobileFooter?.quickLink?.map((link, index) => (
                <span key={index}>
                  {theme?.footer?.divider !== undefined
                    ? ReactHtmlParser(theme.footer?.divider)
                    : `  |  `}
                  <a
                    href={link.url}
                    className={styles["link-text-custom"]}
                    style={{
                      color:
                        theme?.footer?.textColour !== undefined
                          ? theme.footer?.textColour
                          : "#484848",
                      "--hover-color":
                        theme?.footer?.textHoverColour !== undefined
                          ? theme.footer?.textHoverColour
                          : "#008489",
                      fontSize:
                        theme?.footer?.mobileFontSize !== undefined
                          ? theme.footer?.mobileFontSize + "px"
                          : "14px",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </span>
              ))}
            </div>
          ))}
        {!isMobile &&
          (!isValidDesktopFooter ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <span className={styles["footer-inner-left-text"]}>
                <FormattedMessage id="sustainability" />
              </span>
              <img
                src={logoImg}
                alt="logo"
                style={{ marginLeft: "3px" }}
                className={styles["footer-logo"]}
              />{" "}
              <a
                style={{ marginLeft: "3px" }}
                rel="noopener noreferrer"
                target="_blank"
                href="https://yousmartthing.com/uk"
                className={styles["footer-a"]}
              >
                <strong className={styles["link-text"]}>
                  You. Smart. Thing.
                </strong>
              </a>
              <span>
                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                <a
                  className={styles["link-text"]}
                  href="https://yousmartthing.com/uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="menuAbout" />
                </a>
                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                <a
                  className={styles["link-text"]}
                  href="https://yousmartthing.atlassian.net/servicedesk/customer/portals"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="menuHelp" />
                </a>
                &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                <a
                  className={styles["link-text"]}
                  href="https://yousmartthing.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="menuTerms" />
                </a>
              </span>
            </div>
          ) : (
            <div>
              <span>
                {desktopFooter?.heading &&
                  ReactHtmlParser(desktopFooter.heading)}
              </span>
              {desktopFooter?.quickLink?.map((link, index) => (
                <span key={index}>
                  {theme?.footer?.divider !== undefined
                    ? ReactHtmlParser(theme.footer?.divider)
                    : `  |  `}
                  <a
                    href={link.url}
                    className={styles["link-text-custom"]}
                    style={{
                      color:
                        theme?.footer?.textColour !== undefined
                          ? theme.footer?.textColour
                          : "#484848",
                      "--hover-color":
                        theme?.footer?.textHoverColour !== undefined
                          ? theme.footer?.textHoverColour
                          : "#008489",
                      fontSize:
                        theme?.footer?.desktopFontSize !== undefined
                          ? theme.footer?.desktopFontSize + "px"
                          : "15px",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </span>
              ))}
            </div>
          ))}
      </>
    );
  };

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const themeContext = theme?.theme || {};
          var position = themeContext?.footer?.position || "left";

          const footerBackground =
            themeContext?.footer?.backgroundColour !== undefined
              ? themeContext?.footer?.backgroundColour
              : "#ffffff";

          let contentToRender = null;
          if (position === "left") {
            contentToRender = (
              <div className={styles["footer-inner-left"]}>
                {this._renderContent(themeContext)}
              </div>
            );
          } else if (position === "center") {
            contentToRender = (
              <div className={styles["footer-inner-center"]}>
                {this._renderContent(themeContext)}
              </div>
            );
          }

          return (
            <div className={styles["Footer"]}>
              <div
                className={styles["footer-outer-container"]}
                style={{ background: footerBackground }}
              >
                <div className={styles["footer-inner-container"]}>
                  {contentToRender}
                  {!theme?.hideClientElements?.includes("hideShareButton") && (
                    <div className={styles["footer-inner-right"]}>
                      <div
                        style={{
                          display: "flex",
                          float: "right",
                        }}
                        onClick={this.toggleShare}
                      >
                        <strong className={styles["footer-inner-right-span"]}>
                          <FormattedMessage id="share" />
                        </strong>
                        <Share className={styles["footer-logo"]} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default Footer;
